<template>
    <div class="children-attribute">
        <img src="../../../assets/imgs/pc/children/img_shenz@2x.png" alt="兒童身高、體重、頭圍、胸圍介紹">
        <ul class="children-attribute-tabs">
            <router-link to="/children/height" class="children-attribute-tab current">身高</router-link>
            <router-link to="/children/weight" class="children-attribute-tab">體重</router-link>
            <router-link to="/children/bmi" class="children-attribute-tab">BMI</router-link>
            <router-link to="/children/head_circ" class="children-attribute-tab">頭圍</router-link>
            <router-link to="/children/chest_circ" class="children-attribute-tab">胸圍</router-link>
        </ul>
        <div class="children-attribute-content">
            <div style="width: 0px;height: 0px; position: relative; overflow: hidden;"><h1>小孩(兒童)身高對照表</h1></div>
            <p class="children-attribute-item current">
                <span>身長</span>也是小兒生長發育的重要指標，但它反映的是長期營養狀況，短期內影響生長發育的因素（營養、疾病等）對身長影響不明顯。它主要受遺傳、種族和環境的影響較為明顯。身長的增加同體重一樣，也是在生後第一年增長最快，平均年增長25釐米。第二年平均增長10釐米，第三年平均增長4～7.5釐米。 幼兒期孩子的體型由嬰兒期的肥墩型向瘦長型轉變。這期間軀幹稍長些，下肢稍短些。幼兒期後，四肢的增長逐漸快於軀幹的增長。一歲以後平均身長的公式為:身長（cm）=年齡（歲）×5 +80（cm）。小兒的身長與體重都可以用國際衛生組織的標準來評價。
            </p>
        </div>
        <div class="chart">
            <div class="title">0～18歲男孩身高參照值(cm)</div>
            <div class="table">
                <div class="head">
                    <div class="column">年齡</div><div class="column">下等</div><div class="column">中下等</div><div class="column">均值</div><div class="column">中上等</div><div class="column">上等</div>
                </div>
                <div class="content">
                    <div class="row"><div class="column">初生</div><div class="column">47</div><div class="column">48.7</div><div class="column">50.4</div><div class="column">52.1</div><div class="column">53.8</div></div>
                    <div class="row"><div class="column">1月～</div><div class="column">52.3</div><div class="column">54.6</div><div class="column">56.9</div><div class="column">59.2</div><div class="column">61.5</div></div>
                    <div class="row"><div class="column">2月～</div><div class="column">55.6</div><div class="column">58</div><div class="column">60.4</div><div class="column">62.8</div><div class="column">65.2</div></div>
                    <div class="row"><div class="column">3月～</div><div class="column">58.4</div><div class="column">60.7</div><div class="column">63</div><div class="column">65.3</div><div class="column">67.6</div></div>
                    <div class="row"><div class="column">4月～</div><div class="column">60.7</div><div class="column">62.9</div><div class="column">65.1</div><div class="column">67.3</div><div class="column">69.5</div></div>
                    <div class="row"><div class="column">5月～</div><div class="column">62.4</div><div class="column">64.7</div><div class="column">67</div><div class="column">69.3</div><div class="column">71.6</div></div>
                    <div class="row"><div class="column">6月～</div><div class="column">64.4</div><div class="column">66.8</div><div class="column">69.2</div><div class="column">71.6</div><div class="column">74</div></div>
                    <div class="row"><div class="column">8月～</div><div class="column">67</div><div class="column">69.5</div><div class="column">72</div><div class="column">74.5</div><div class="column">77</div></div>
                    <div class="row"><div class="column">10月～</div><div class="column">69.4</div><div class="column">72</div><div class="column">74.6</div><div class="column">77.2</div><div class="column">79.8</div></div>
                    <div class="row"><div class="column">12月～</div><div class="column">71.9</div><div class="column">74.6</div><div class="column">77.3</div><div class="column">80</div><div class="column">82.7</div></div>
                    <div class="row"><div class="column">15月～</div><div class="column">74.7</div><div class="column">77.5</div><div class="column">80.3</div><div class="column">83.1</div><div class="column">85.9</div></div>
                    <div class="row"><div class="column">18月～</div><div class="column">76.5</div><div class="column">79.6</div><div class="column">82.7</div><div class="column">85.8</div><div class="column">88.9</div></div>
                    <div class="row"><div class="column">21月～</div><div class="column">79.2</div><div class="column">82.4</div><div class="column">85.6</div><div class="column">88.8</div><div class="column">92</div></div>
                    <div class="row"><div class="column">2.0歲～</div><div class="column">82.3</div><div class="column">85.7</div><div class="column">89.1</div><div class="column">92.5</div><div class="column">95.9</div></div>
                    <div class="row"><div class="column">2.5歲～</div><div class="column">86.3</div><div class="column">89.8</div><div class="column">93.3</div><div class="column">96.8</div><div class="column">100.3</div></div>
                    <div class="row"><div class="column">3.0歲～</div><div class="column">89.4</div><div class="column">93.1</div><div class="column">96.8</div><div class="column">100.5</div><div class="column">104.2</div></div>
                    <div class="row"><div class="column">3.5歲～</div><div class="column">92.6</div><div class="column">96.4</div><div class="column">100.2</div><div class="column">104</div><div class="column">107.8</div></div>
                    <div class="row"><div class="column">4.0歲～</div><div class="column">95.5</div><div class="column">99.6</div><div class="column">103.7</div><div class="column">107.8</div><div class="column">111.9</div></div>
                    <div class="row"><div class="column">4.5歲～</div><div class="column">98.9</div><div class="column">103</div><div class="column">107.1</div><div class="column">111.2</div><div class="column">115.3</div></div>
                    <div class="row"><div class="column">5.0歲～</div><div class="column">102.1</div><div class="column">106.3</div><div class="column">110.5</div><div class="column">114.7</div><div class="column">118.9</div></div>
                    <div class="row"><div class="column">5.5歲～</div><div class="column">104.7</div><div class="column">109.2</div><div class="column">113.7</div><div class="column">118.2</div><div class="column">122.7</div></div>
                    <div class="row"><div class="column">6.0歲～</div><div class="column">108.5</div><div class="column">113.2</div><div class="column">117.9</div><div class="column">122.6</div><div class="column">127.3</div></div>
                    <div class="row"><div class="column">7.0歲～</div><div class="column">112.5</div><div class="column">118.2</div><div class="column">123.9</div><div class="column">129.6</div><div class="column">135.3</div></div>
                    <div class="row"><div class="column">8.0歲～</div><div class="column">116.8</div><div class="column">122.7</div><div class="column">128.6</div><div class="column">134.5</div><div class="column">140.4</div></div>
                    <div class="row"><div class="column">9.0歲～</div><div class="column">121.6</div><div class="column">127.7</div><div class="column">133.8</div><div class="column">139.9</div><div class="column">146</div></div>
                    <div class="row"><div class="column">10歲～</div><div class="column">125.6</div><div class="column">132.2</div><div class="column">138.8</div><div class="column">145.4</div><div class="column">152</div></div>
                    <div class="row"><div class="column">11歲～</div><div class="column">130.1</div><div class="column">137.3</div><div class="column">144.5</div><div class="column">151.7</div><div class="column">158.9</div></div>
                    <div class="row"><div class="column">12歲～</div><div class="column">134</div><div class="column">142.2</div><div class="column">150.4</div><div class="column">158.6</div><div class="column">166.8</div></div>
                    <div class="row"><div class="column">13歲～</div><div class="column">142.1</div><div class="column">150.4</div><div class="column">158.7</div><div class="column">167</div><div class="column">175.3</div></div>
                    <div class="row"><div class="column">14歲～</div><div class="column">149.5</div><div class="column">156.9</div><div class="column">164.3</div><div class="column">171.7</div><div class="column">179.1</div></div>
                    <div class="row"><div class="column">15歲～</div><div class="column">155</div><div class="column">161.3</div><div class="column">167.6</div><div class="column">173.9</div><div class="column">180.2</div></div>
                    <div class="row"><div class="column">16歲～</div><div class="column">157.3</div><div class="column">163.4</div><div class="column">169.5</div><div class="column">175.6</div><div class="column">181.7</div></div>
                    <div class="row"><div class="column">17～18歲</div><div class="column">158.3</div><div class="column">164.3</div><div class="column">170.3</div><div class="column">176.3</div><div class="column">182.3</div></div>
                </div>
            </div>
        </div>
        <div class="chart">
            <div class="title">0～18歲女孩身高參照值(cm)</div>
            <div class="table">
                <div class="head">
                    <div class="column">年齡</div><div class="column">下等</div><div class="column">中下等</div><div class="column">均值</div><div class="column">中上等</div><div class="column">上等</div>
                </div>
                <div class="content">
                    <div class="row"><div class="column">初生</div><div class="column">46.6</div><div class="column">48.2</div><div class="column">49.8</div><div class="column">51.4</div><div class="column">53</div></div>
                    <div class="row"><div class="column">1月～</div><div class="column">51.7</div><div class="column">53.9</div><div class="column">56.1</div><div class="column">58.3</div><div class="column">60.5</div></div>
                    <div class="row"><div class="column">2月～</div><div class="column">54.6</div><div class="column">56.9</div><div class="column">59.2</div><div class="column">61.5</div><div class="column">63.8</div></div>
                    <div class="row"><div class="column">3月～</div><div class="column">57.2</div><div class="column">59.4</div><div class="column">61.6</div><div class="column">63.8</div><div class="column">66</div></div>
                    <div class="row"><div class="column">4月～</div><div class="column">59.4</div><div class="column">61.6</div><div class="column">63.8</div><div class="column">66</div><div class="column">68.2</div></div>
                    <div class="row"><div class="column">5月～</div><div class="column">60.9</div><div class="column">63.2</div><div class="column">65.5</div><div class="column">67.8</div><div class="column">70.1</div></div>
                    <div class="row"><div class="column">6月～</div><div class="column">62.8</div><div class="column">65.2</div><div class="column">67.6</div><div class="column">70</div><div class="column">72.4</div></div>
                    <div class="row"><div class="column">8月～</div><div class="column">65.6</div><div class="column">68.1</div><div class="column">70.6</div><div class="column">73.1</div><div class="column">75.6</div></div>
                    <div class="row"><div class="column">10月～</div><div class="column">68.1</div><div class="column">70.7</div><div class="column">73.3</div><div class="column">75.9</div><div class="column">78.5</div></div>
                    <div class="row"><div class="column">12月～</div><div class="column">70.3</div><div class="column">73.1</div><div class="column">75.9</div><div class="column">78.7</div><div class="column">81.5</div></div>
                    <div class="row"><div class="column">15月～</div><div class="column">73.3</div><div class="column">76.1</div><div class="column">78.9</div><div class="column">81.7</div><div class="column">84.5</div></div>
                    <div class="row"><div class="column">18月～</div><div class="column">75.8</div><div class="column">78.7</div><div class="column">81.6</div><div class="column">84.5</div><div class="column">87.4</div></div>
                    <div class="row"><div class="column">21月～</div><div class="column">78.5</div><div class="column">81.5</div><div class="column">84.5</div><div class="column">87.5</div><div class="column">90.5</div></div>
                    <div class="row"><div class="column">2.0歲～</div><div class="column">81.3</div><div class="column">84.7</div><div class="column">88.1</div><div class="column">91.5</div><div class="column">94.9</div></div>
                    <div class="row"><div class="column">2.5歲～</div><div class="column">84.8</div><div class="column">88.4</div><div class="column">92</div><div class="column">95.6</div><div class="column">99.2</div></div>
                    <div class="row"><div class="column">3.0歲～</div><div class="column">88.7</div><div class="column">92.3</div><div class="column">95.9</div><div class="column">99.5</div><div class="column">103.1</div></div>
                    <div class="row"><div class="column">3.5歲～</div><div class="column">91.6</div><div class="column">95.4</div><div class="column">99.2</div><div class="column">103</div><div class="column">106.8</div></div>
                    <div class="row"><div class="column">4.0歲～</div><div class="column">95</div><div class="column">98.9</div><div class="column">102.8</div><div class="column">106.7</div><div class="column">110.6</div></div>
                    <div class="row"><div class="column">4.5歲～</div><div class="column">97.8</div><div class="column">102</div><div class="column">106.2</div><div class="column">110.4</div><div class="column">114.6</div></div>
                    <div class="row"><div class="column">5.0歲～</div><div class="column">101.6</div><div class="column">105.7</div><div class="column">109.8</div><div class="column">113.9</div><div class="column">118</div></div>
                    <div class="row"><div class="column">5.5歲～</div><div class="column">103.9</div><div class="column">108.4</div><div class="column">112.9</div><div class="column">117.4</div><div class="column">121.9</div></div>
                    <div class="row"><div class="column">6.0歲～</div><div class="column">108.1</div><div class="column">112.6</div><div class="column">117.1</div><div class="column">121.6</div><div class="column">126.1</div></div>
                    <div class="row"><div class="column">7.0歲～</div><div class="column">111.7</div><div class="column">117.2</div><div class="column">122.7</div><div class="column">128.2</div><div class="column">133.7</div></div>
                    <div class="row"><div class="column">8.0歲～</div><div class="column">116.2</div><div class="column">122</div><div class="column">127.8</div><div class="column">133.6</div><div class="column">139.4</div></div>
                    <div class="row"><div class="column">9.0歲～</div><div class="column">120.7</div><div class="column">127.1</div><div class="column">133.5</div><div class="column">139.9</div><div class="column">146.3</div></div>
                    <div class="row"><div class="column">10歲～</div><div class="column">125.3</div><div class="column">132.4</div><div class="column">139.5</div><div class="column">146.6</div><div class="column">153.7</div></div>
                    <div class="row"><div class="column">11歲～</div><div class="column">131.6</div><div class="column">138.9</div><div class="column">146.2</div><div class="column">153.5</div><div class="column">160.8</div></div>
                    <div class="row"><div class="column">12歲～</div><div class="column">138.5</div><div class="column">145.1</div><div class="column">151.7</div><div class="column">158.3</div><div class="column">164.9</div></div>
                    <div class="row"><div class="column">13歲～</div><div class="column">143.7</div><div class="column">149.7</div><div class="column">155.7</div><div class="column">161.7</div><div class="column">167.7</div></div>
                    <div class="row"><div class="column">14歲～</div><div class="column">146</div><div class="column">151.6</div><div class="column">157.2</div><div class="column">162.8</div><div class="column">168.4</div></div>
                    <div class="row"><div class="column">15歲～</div><div class="column">147.3</div><div class="column">152.8</div><div class="column">158.3</div><div class="column">163.8</div><div class="column">169.3</div></div>
                    <div class="row"><div class="column">16歲～</div><div class="column">147.6</div><div class="column">153.1</div><div class="column">158.6</div><div class="column">164.1</div><div class="column">169.6</div></div>
                    <div class="row"><div class="column">17～18歲</div><div class="column">147.7</div><div class="column">153.2</div><div class="column">158.7</div><div class="column">164.2</div><div class="column">169.7</div></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	export default {
        created: function(){
            if(this.$route.path.indexOf('/children/height') != -1) {
                setTimeout(() => {
                    window.scrollTo(0, 960)    
                }, 0);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .children-attribute {
        width: 1000px;
        margin: 0px auto;
        position: relative;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    .children-attribute > img {
        position: absolute;
        right: 0px;
        top: 50px;
        width: 440px;
        height: 468px;
    }

    .children-attribute > .children-attribute-n {
        display: inline-block;
        width: 52px;
        height: 57px;
        position: absolute;
        left: 0px;
        top: 62px;
        background-repeat: no-repeat;
        background-image: url(../../../assets/imgs/pc/children/img_shuye@2x.png);
        background-size: 100% 100%;
        font-size: 30px;
        color: #36C4D0;
        font-weight: bold;
        box-sizing: border-box;
        line-height: 24px;
        padding: 10px 0px 0px 11px;
    }

    .children-attribute > .children-attribute-tabs {
        margin-top: 90px;
        height: 34px;
        display: block;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }

    .children-attribute > .children-attribute-tabs > .children-attribute-tab {
        height: 34px;
        font-size: 18px;
        color: #231F20;
        font-weight: bold;
        display: inline-block;
        margin-right: 48px;
        cursor: pointer;
        user-select: none;
    }

    .children-attribute > .children-attribute-tabs > .children-attribute-tab.current {
        color: #36C4D0;
        position: relative;
    }

    .children-attribute > .children-attribute-tabs > .children-attribute-tab.current::after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: 0px;
        height: 4px;
        width: 12px;
        background-color: #36C4D0;
        transform: translateX(-50%);
    }


    .children-attribute > .children-attribute-content {
        position: relative;
        height: 317px;
        width: 750px;
        overflow: hidden;
        margin-top: 89px;
    }

    .children-attribute > .children-attribute-content > .children-attribute-item {
        width: 750px;
        position: absolute;
        left: 750px;
        top: 0px;
        width: 750px;
        justify-content: space-between;
        background-color: rgba(239, 247, 248, 0.6);
        padding: 21px 18px 31px 20px;
        font-size: 16px;
        font-weight: 400;
        color: #636363;
        line-height: 24px;
    }

    .children-attribute > .children-attribute-content > .children-attribute-item.current {
        left: 0px;
    }

    .children-attribute > .children-attribute-content > .children-attribute-item::first-line {
        line-height: 40px;
    }

    .children-attribute > .children-attribute-content > .children-attribute-item > span {
        display: inline-block;
        margin-left: 33px;
        font-size: 24px;
        line-height: 24px;
        color: #231F20;
        font-weight: bold;
    }

    .children-attribute > .children-attribute-content > .children-attribute-item > .children-attribute-item-l {
        margin-top: 41px;
        margin-top: 29px;
        display: inline-block;
        height: 21px;
        line-height: 21px;
        font-size: 18px;
        color: #FF8000;
        font-weight: bold;
        text-decoration: underline;
        margin-bottom: 49px;
    }

    .chart {
        margin-bottom: 20px;
    }

    .chart,
    .chart > .table {
        display: flex;
        flex-direction: column;
    }

    .chart > .title {
        height: 77px;
        line-height: 77px;
        color: #242020;
        font-size: 16px;
        font-weight: bold;
        position: relative;
        padding-left: 24px;
        box-sizing: border-box;
    }

    .chart > .title::after {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 15px;
        height: 16px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url(../../../assets/imgs/pc/children/img_copy@2x.png);;
    }

    .chart > .table > .content > .row,
    .chart > .table > .head {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        background-color: #EEF9FA;
        height: 30px;
        align-items: center;
    }

    .chart > .table > .head {
        border-bottom: 1px solid #CEE5E7;
    }

    .chart:last-child {
        margin-bottom: 70px;
    }
    
    .chart:last-child > .table > .head {
        background-color: #FFE7E7;
        border-bottom: 1px solid #E8C5C5;
    }


    .chart > .table > .content > .row {
        background-color: #fff;
    }

    .chart > .table > .content > .row:nth-child(odd) {
        background-color: #F6F7F8;
    }

    .chart > .table > .head > .column,
    .chart > .table > .content > .row > .column {
        width: 100%;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        color: #646464;
    }

    .chart > .table > .head > .column {
        color: #242020;
        font-size: 14px;
        font-weight: bold;
    }
</style>
